const elementHeight = {
  data() {
    return {
      headerHeight: "1.06667rem", // 头部高度
      fixedFooterHeight: "1.76rem",
      paddingBottom: "1.90rem"
    }
  }
}

export default elementHeight
