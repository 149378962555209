<template>
  <div class="promo-header bottom-shadowed" :style="{ height }">
    <div class="left flex-center" :style="{ width: height }" @click="back">
      <img class="back-icon" src="@/assets/images/h5/back-icon.png" alt="" />
    </div>
    <div class="center flex-center">{{ $route.meta.title || "" }}</div>
    <div class="right flex-center" :style="{ width: height }"></div>
  </div>
</template>

<script>
export default {
  name: "PromoHeader",
  props: {
    height: {
      type: String,
      required: true,
    },
  },
  methods: {
    back() {
      // this.$router.push("/m");
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
.promo-header {
  display: flex;
  justify-content: space-between;
  text-align: center;
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #fff;

  .left,
  .right {
    flex: none;

    .back-icon {
      height: 14px;
    }
  }
}
</style>
